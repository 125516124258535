// Карточка Правовой акт
import { hasPermission } from '@/store/modules/permission'
import store from '@/store'

export const LEGAL_ACT_FULL_ACCESS = 'legal_act_full_access' // Полный доступ: чтение, создание, редактирование, удаление
export const LEGAL_ACT_READ = 'legal_act_read' // Чтение всех Правовой акт
export const LEGAL_ACT_CREATE = 'legal_act_create'
export const LEGAL_ACT_EDIT = 'legal_act_edit'
export const LEGAL_ACT_DELETE = 'legal_act_delete'
export const LEGAL_ACT_RESTORE = 'legal_act_restore'
export const LEGAL_ACT_EDIT_KTZN = 'legal_act_edit_ktzn'
export const LEGAL_ACT_DELETE_KTZN = 'legal_act_delete_ktzn'
export const LEGAL_ACT_RESTORE_KTZN = 'legal_act_restore_ktzn'
export const LEGAL_ACT_SIGN_WITH_EDS = 'legal_act_sign_with_eds' // Кнопка "Подписать"
export const LEGAL_ACT_VIEW_EDS_SIGNED_FILE = 'legal_act_view_eds_signed_file' // Поле "Документ, утверждённый с ЭЦП"
export const LEGAL_ACT_SEND_TO_IOGV_HEAD = 'legal_act_send_to_iogv_head' // Кнопка "Отправить"

export const permissions = {
  create: [LEGAL_ACT_FULL_ACCESS, LEGAL_ACT_CREATE],
  edit: [LEGAL_ACT_FULL_ACCESS, LEGAL_ACT_EDIT, LEGAL_ACT_EDIT_KTZN],
  delete: [LEGAL_ACT_FULL_ACCESS, LEGAL_ACT_DELETE, LEGAL_ACT_DELETE_KTZN],
  restore: [LEGAL_ACT_FULL_ACCESS, LEGAL_ACT_RESTORE, LEGAL_ACT_RESTORE_KTZN],
  signEDS: [LEGAL_ACT_FULL_ACCESS, LEGAL_ACT_SIGN_WITH_EDS],
  viewEDSSignedFile: [LEGAL_ACT_FULL_ACCESS, LEGAL_ACT_SIGN_WITH_EDS, LEGAL_ACT_VIEW_EDS_SIGNED_FILE],
  sendToIogvHead: [LEGAL_ACT_FULL_ACCESS, LEGAL_ACT_SEND_TO_IOGV_HEAD]
}

class LegalActService {
  canSignEDS () {
    return hasPermission(store.state.user.permissions, permissions.signEDS)
  }

  canViewEDSSignedFile () {
    return hasPermission(store.state.user.permissions, permissions.viewEDSSignedFile)
  }

  canSendToIogvHead () {
    return hasPermission(store.state.user.permissions, permissions.sendToIogvHead)
  }
}

export const legalActService = new LegalActService()
