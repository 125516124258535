// Карточка План КНМ
import store from '@/store'
import { hasPermission } from '@/store/modules/permission'

export const PLAN_KNM_FULL_ACCESS = 'plan_knm_full_access' // Полный доступ: чтение, создание, редактирование, удаление
export const PLAN_KNM_READ = 'plan_knm_read' // Чтение всех План КНМ
export const PLAN_KNM_CREATE = 'plan_knm_create' // Создание План КНМ
export const PLAN_KNM_EDIT_ORG = 'plan_knm_edit_org' // Редактирование План КНМ которые принадлежат к его ИОГВ. Даже если создавал не он
export const PLAN_KNM_EDIT_KTZN = 'plan_knm_edit_ktzn' // Редактирование План КНМ только своих (*Кто создал, т.е. автор карточки)
export const PLAN_KNM_DELETE_KTZN = 'plan_knm_delete_ktzn' // Удаление План КНМ только своих (*Кто создал, т.е. автор карточки)
export const PLAN_KNM_DELETE = 'plan_knm_delete'
export const PLAN_KNM_RESTORE = 'plan_knm_restore'
export const PLAN_KNM_RESTORE_KTZN = 'plan_knm_restore_ktzn'
export const PLAN_KNM_SIGN_WITH_EDS = 'plan_knm_sign_with_eds' // Кнопка "Подписать"
export const PLAN_KNM_VIEW_EDS_SIGNED_FILE = 'plan_knm_view_eds_signed_file' // Поле "Документ, утверждённый с ЭЦП"

export const permissions = {
  create: [PLAN_KNM_FULL_ACCESS, PLAN_KNM_CREATE],
  edit: [PLAN_KNM_FULL_ACCESS, PLAN_KNM_EDIT_ORG, PLAN_KNM_EDIT_KTZN],
  delete: [PLAN_KNM_FULL_ACCESS, PLAN_KNM_DELETE_KTZN, PLAN_KNM_DELETE],
  restore: [PLAN_KNM_FULL_ACCESS, PLAN_KNM_RESTORE, PLAN_KNM_RESTORE_KTZN],
  signEDS: [PLAN_KNM_FULL_ACCESS, PLAN_KNM_SIGN_WITH_EDS],
  viewEDSSignedFile: [PLAN_KNM_FULL_ACCESS, PLAN_KNM_SIGN_WITH_EDS, PLAN_KNM_VIEW_EDS_SIGNED_FILE]
}

class PlanService {
  canSignEDS () {
    return hasPermission(store.state.user.permissions, permissions.signEDS)
  }

  canViewEDSSignedFile () {
    return hasPermission(store.state.user.permissions, permissions.viewEDSSignedFile)
  }
}

export const planService = new PlanService()
